import React from 'react'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import { StaticQuery, graphql } from 'gatsby'
import IconLinkedIn from 'images/icon-linkedin.svg'
import Img from 'gatsby-image'
import {
  Name,
  PhotoWrapper,
  ContentColumn,
  Title,
  Description,
  LinkedInAnchor,
  Separator,
} from './styled'

const Content = () => (
  <StaticQuery
    query={graphql`
      query {
        photoSadowski: file(relativePath: { eq: "sadowski.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <Container>
        <Row>
          <Col lg={4}>
            <PhotoWrapper hiddenTablet>
              <Img fluid={data.photoSadowski.childImageSharp.fluid} />

              <LinkedInAnchor
                href="https://www.linkedin.com/in/robert-sadowski-5871a676"
                target="_blank"
                rel="noopener"
              >
                <img src={IconLinkedIn} alt="LinkedIn" /> Visit my Linkedin
                profile
              </LinkedInAnchor>
            </PhotoWrapper>
          </Col>
          <ContentColumn md={12} lg={8}>
            <Row>
              <Col lg={12}>
                <Row>
                  <Col md={6}>
                    <PhotoWrapper tablet>
                      <Img fluid={data.photoSadowski.childImageSharp.fluid} />

                      <LinkedInAnchor
                        href="https://www.linkedin.com/in/robert-sadowski-5871a676"
                        target="_blank"
                        rel="noopener"
                      >
                        <img src={IconLinkedIn} alt="LinkedIn" /> Visit my
                        Linkedin profile
                      </LinkedInAnchor>
                    </PhotoWrapper>
                  </Col>
                  <Col lg={12} md={6}>
                    <Name>Robert W. Sadowski</Name>
                  </Col>
                </Row>
                <Title>Biography</Title>
                <Description>
                  Robert W. Sadowski is a trial and appellate attorney
                  specializing in qui tam cases. He is an experienced litigator,
                  and has successfully prosecuted cases in many areas,
                  including; elder care fraud, disability rights, wrongful
                  termination cases, bankruptcy litigation, investment fraud,
                  and tax fraud.
                  <br />
                  <br />
                  Before going into private practice, Rob held the position of
                  health care fraud coordinator in the United States Attorney’s
                  Office for the Southern District of New York, where he was
                  responsible for supervising all civil health care fraud
                  prosecutions. He also supervised the investigation, litigation
                  and settlement of fraud prosecutions under the False Claims
                  Act.
                  <br />
                  <br />
                  Prior to working for the United States Attorney, Rob, worked
                  as an Internal Revenue Service trial attorney for four years.
                </Description>
              </Col>
            </Row>

            <Row>
              <Col md={6} lg={6}>
                <Title>Speaking Engagements / Affiliations</Title>
                <Description>
                  Rob is an adjunct professor at Brooklyn Law School and a
                  faculty member of Lawline. Rob frequently speaks to lawyers,
                  accountants and health care professionals about whistleblowers
                  and the False Claims Act, fraud topics, and healthcare law
                  issues, and has made numerous television appearances.
                </Description>

                <Title>Awards</Title>
                <Description>
                  United States Attorney’s Office Attorney General’s John
                  Marshall Award
                  <br />
                  <br />
                  United States Attorney’s Office Director’s Award for Superior
                  Performance
                  <br />
                  <br />
                  Super Lawyer 2008 through present
                </Description>
              </Col>
              <Col md={6} lg={6}>
                <Title>Admissions</Title>
                <Description>
                  Rob is admitted to practice in New York, the United States
                  District Courts for the Southern, Eastern, and Northern
                  Districts of New York, and the Court of Appeals for the First
                  and Second Circuits.
                </Description>

                <Title>Education</Title>
                <Description>
                  Rob has a B.A. from the University of Wisconsin, a J.D. from
                  Brooklyn Law School, and an LL.M in Taxation from NYU Law
                  School.
                </Description>

                <Title>Memberships</Title>
                <Description>Taxpayers Against Fraud Education</Description>
              </Col>
            </Row>
          </ContentColumn>
        </Row>
      </Container>
    )}
  />
)

export default Content
