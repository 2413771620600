import React from 'react'

import Navbar from 'components/shared/Navbar'
import Footer from 'components/shared/Footer'
import Layout from 'components/shared/Layout'
import PageTitle from 'components/shared/PageTitle'
import Content from 'components/About/Content'

const About = () => (
  <Layout>
    <Navbar />
    <PageTitle title="About" subtitle="Who are we?" />
    <Content />
    <Footer />
  </Layout>
)

export default About
