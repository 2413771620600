import styled from 'styled-components'
import { Col } from 'styled-bootstrap-grid'
import { media } from 'themes'

export const PhotoWrapper = styled.div`
  width: calc(100% + 30px);

  ${({ tablet }) => tablet && `
    display: none;
  `}

  ${media.md`
    width: 100%;
    
    ${({ tablet, hiddenTablet }) => {
      if (tablet) {
        return `
          display: block;
        `;
      }

      if (hiddenTablet) {
        return `
          display: none;
        `
      }
    }}
  `}

  ${media.sm`
    width: 100%;

    ${({ tablet, hiddenTablet, spaceBottom }) => {
      let styles = ``;

      if (tablet) {
        styles += `
          display: none;
        `;
      }

      if (hiddenTablet) {
        styles += `
          display: block;
        `
      }

      if (spaceBottom) {
        styles += `
          margin-bottom: 30px;
        `;
      }

      return styles;
    }}
  `}
`

export const ContentColumn = styled(Col)`
  padding-left: 70px;

  ${media.md`
    padding-left: 15px;
  `}
`

export const Title = styled.h3`
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 24px;
`

export const Description = styled.div`
  color: #5a5a5a;
  font-family: Futura;
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 48px;
`

export const LinkedInAnchor = styled.a`
  display: inline-flex;
  align-items: center;
  color: #c74513;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  margin-top: 24px;
  text-decoration: none;

  img {
    margin-right: 10px;
  }

  &:hover {
    text-decoration: underline;
  }

  ${media.md`
    margin-bottom: 48px;
  `}

  ${media.sm`
    margin-left: 15px;
  `}
`

export const Name = styled.div`
  font-family: 'Playfair Display';
  color: #000000;
  font-size: 28px;
  line-height: 32px;
  font-weight: bold;
  margin-bottom: 30px;
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background: #CCC;
  margin: 17px 0 65px;
`;